/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 38번째 로그에서는 RubyGems strong_password 해킹 사건, 소프트웨어 환멸감, Zoom MacOS 클라이언트 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 클라우드, 소프트웨어 개발, 전자 제품에 대해 이야기하는 프로그래머들의 팟캐스트 | Patreon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cojette.github.io/hireworkmate/"
  }, "같이 일할 동료를 찾아요 – Cojette (꼬젯) – 잡덕 잉여 데이터 분석가의 이것저것")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "-"
  }, "당근마켓과 함께 할 개발자를 찾고 있어요!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/sue445/status/1119805390588235778"
  }, "(일본어) sue445 on Twitter: \"RubyGems의 MFA 설정 후에 rake release가 안 되서 해매고 있다가, 마침 @hsbt 씨가 옆에 있어서 해결했어요. (진행이 멈추면 OTP 코드를 넣으면 넘어가요)  #RubyKaigi #fukuokarb / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.bitrise.io/app-store-connect-2fa-solved-on-bitrise"
  }, "App Store Connect 2FA solved on Bitrise | Bitrise Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://withatwist.dev/strong-password-rubygem-hijacked.html"
  }, "strong_password v0.0.7 rubygem hijacked")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.npmjs.org/post/180565383195/details-about-the-event-stream-incident"
  }, "The npm Blog — Details about the event-stream incident")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ruby-doc.org/core-2.6.3/Kernel.html#method-i-eval"
  }, "Module: Kernel#eval (Ruby 2.6.3)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/rafaelfranca"
  }, "Ruby on Rails Security coordinator - Rafael França (@rafaelfranca) / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://guides.rubygems.org/command-reference/#gem-yank"
  }, "Command Reference - RubyGems Guides")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.reddit.com/r/programming/comments/cakqa5/ruby_gem_strong_password_got_hijacked/"
  }, "Ruby gem strong_password got hijacked : programming")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://userinyerface.com/game.html"
  }, "User Inyerface - A worst-practice UI experiment")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://muchtrans.com/translations/software-disenchantment.ko.html?fbclid=IwAR3qTSZa7ipdLWkskdBwi2iLwM5SEDsSa-Jy5Mg0V48z9ZIEAZ8wexnPDcw"
  }, "소프트웨어 환멸감")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bose.com/en_us/products/headphones/noise_cancelling_headphones/noise-cancelling-headphones-700.html"
  }, "Smart Noise Cancelling Headphones 700 | Bose")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bose.com/en_us/products/headphones/earphones/quietcontrol-30.html"
  }, "QuietControl 30 (QC30) Wireless Noise Cancelling Earbuds | Bose")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gnu.org/software/emacs/"
  }, "GNU Emacs - GNU Project")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tonsky.me/blog/disenchantment/"
  }, "Software disenchantment @ tonsky.me")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tonsky.me/blog/github-redesign/"
  }, "Redesigning Github repository page @ tonsky.me")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/bugbountywriteup/zoom-zero-day-4-million-webcams-maybe-an-rce-just-get-them-to-visit-your-website-ac75c83f4ef5"
  }, "Zoom Zero Day: 4+ Million Webcams & maybe an RCE? Just get them to visit your website!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.zoom.us/wordpress/2019/07/08/response-to-video-on-concern/"
  }, "Response to Video-On Concern - Zoom Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://macnews.tistory.com/5742"
  }, "애플, 맥북 프로와 맥북 에어 라인업 업데이트 :: Back to the Mac 블로그")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
